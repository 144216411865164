/* shared app-styles.css */

.bg-inherit {
    background-color: inherit;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/* Ripple effect for on screen keyboard */
.hg-button {
    background: #fff;
    background-position: center!important;
    transition: background 0.8s!important;
}
.hg-button:hover {
    background: #dcdcdc radial-gradient(circle, transparent 1%, #c8c8c8 1%) center/15000%!important;
}
.hg-button:active {
    background-color: #fff!important;
    background-size: 100%!important;
    transition: background 0s!important;
}

.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
