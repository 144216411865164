.pin-input {
}
.pin-input .pin-content {
  width: 920px;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 30px;
  display: flex;
  align-items: flex-end;
}
.pin-input .password-char-container {
  display: flex;
}
.pin-input .password-char {
  margin: 0 5px;
}
.pin-input .password-dot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}
.pin-input .underline-container {
  height: 4px;
}
.pin-input .underline-container .underline {
  width: 40px;
  height: 2px;
}
.pin-input .underline-container .underline-active {
  height: 4px;
}
.pin-input .number-input-container-width {
  width: 280px;
}
.pin-input .number-input-container {
  display: flex;
  /* per row: 3 * 80px (3 number-buttons) + 2 * 20px (left+right margin in the middle) */
  width: 280px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pin-input .number-input {
  margin-bottom: 20px;
  width: 80px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 36px;
}
.pin-input .cursor {
  width: 2px;
  height: 50px;
  animation: 2s cursor-blink step-end infinite;
}
@keyframes cursor-blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: rgba(var(--color-rgb-primary-500), 1);
  }
}
.pin-input .relative {
  position: relative;
}
.pin-input .absolute-pin-shadow {
  width: 300px;
  height: 25px;
  border-radius: 50%;
  filter: blur(5px);
}
.pin-input .ml-20 {
  margin-left: 20px;
}
.pin-input .mb-0 {
  margin-bottom: 0;
}
.pin-input .justify-content-end {
  justify-content: flex-end;
}
.pin-input .h-50px {
  height: 50px;
}
.pin-input .mb-10 {
  margin-bottom: 10px;
}
