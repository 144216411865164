@media print {
  /* remove footer and header for printing: https://stackoverflow.com/questions/1960939/disabling-browser-print-options-headers-footers-margins-from-page */
  @page {
    margin: 0; /* this affects the margin in the printer settings */
  }
  /*  hide everything but not the print stuff */
  body > :not(.print) {
    display: none !important;
  }
  /* overwrite hard the block display: */
  .print {
    display: block !important;
  }
  .print {
    /* remove footer and header for printing: https://stackoverflow.com/questions/1960939/disabling-browser-print-options-headers-footers-margins-from-page */
    margin: 10mm 15mm 10mm 20mm;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  .briefkopf {
    height: 35mm;
    margin-bottom: 5mm;
  }

  .briefkopf-firmenlogo {
    height: 25mm;
  }

  /* https://www.webmasterpro.de/design/article/briefpapier-richtig-anlegen.html */
  .adressfenster {
    margin-top: 40mm; /* sodass es perfekt im Brieffenster liegt:  */
    padding: 2mm;
    height: 40mm;
    width: 90mm;
    box-sizing: border-box;
  }

  /* damit rechte seite von der Rechnung gleiche Abstände aufweist */
  .adressfenster-padding {
    padding-top: 2mm;
    height: 40mm; /* gleiche Höhe nehmen, weil rechts eigentlich */
  }

  .rechnung-headline {
    margin-top: 5mm;
  }
}
