.GlobalNoOpaqueAppLoadingScreen-loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  --color-rgb-primary-100: 236, 244, 245;
  background-color: rgb(var(--color-rgb-primary-100));
  z-index: 100000;
}
